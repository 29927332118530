// Dependent colors
$black: #000000;
$white: #ffffff;
$purple: #c8c4dd;
$blue: #c8dbee;
$green: #dff6eb;
$pink: #ffcbcb;
$blueberry: #5971ad;
$cream: #fffdef;
$bilberry: #ad599c;

$classic-base-color: #282c37;
$classic-primary-color: #9baec8;
$classic-secondary-color: $green;
$classic-highlight-color: #2b90d9;

// Differences
$success-green: #3c754d;

$base-overlay-background: $white !default;
$valid-value-color: $success-green !default;

$ui-base-color: $classic-secondary-color !default;
$ui-base-lighter-color: $cream;
$ui-primary-color: #9bcbed;
$ui-secondary-color: $classic-base-color !default;
$ui-highlight-color: $blueberry;
$ui-button-secondary-color: $bilberry;
$ui-button-secondary-border-color: $bilberry;
$ui-button-secondary-focus-background-color: $pink;

$primary-text-color: $black !default;
$darker-text-color: $classic-base-color !default;
$dark-text-color: #444b5d;
$action-button-color: #606984;

$inverted-text-color: $black !default;
$lighter-text-color: $classic-base-color !default;
$light-text-color: #444b5d;

$ui-button-secondary-focus-color: $light-text-color;

// Newly added colors
$account-background-color: $white !default;

// Invert darkened and lightened colors
@function darken($color, $amount) {
  @return hsl(hue($color), saturation($color), lightness($color) + $amount);
}

@function lighten($color, $amount) {
  @return hsl(hue($color), saturation($color), lightness($color) - $amount);
}
